import React from 'react'
import PageContainer from '../../componets/pageContainer'
import { Link } from 'react-router-dom'

function AboutPageContent() {
    return (
        <div className='page-content'>
            <div className="features-title">
                <div className="icon">I</div>
                <h1>Introduction</h1>
            </div>
            <div>
                <h3>What is Q-control</h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam cupiditate optio ipsum omnis, magnam recusandae impedit eveniet earum, quasi autem, cum in beatae quia. Error omnis ipsa magni quo temporibus.</p>

            </div>
            <h3>Key features</h3>
            <div className="features-cards cards">
                {
                    features && features.map((feature) => (
                        <div className="card">
                            <div className="card-title">
                                <div className="icon">{feature.icon}</div>
                                <h3>{feature.title}</h3>
                            </div>
                            <p>{feature.description}</p>
                        </div>
                    ))
                }
            </div>

            <Link>Click here to login</Link>
        </div>
    )
}


const AboutPage = () => {
    return (
        <PageContainer content={<AboutPageContent />} />
    )
}
export default AboutPage

const features = [
    {
        icon: 'A',
        title: 'Authentication',
        description: 'A feature that handles login, password reset, permissions'
    },
    // Add more features here
    {
        icon: 'I',
        title: 'Incident tracking',
        description: 'A feature that handles adding incidents, modifying and taking corrective actions'
    },
    {
        icon: 'C',
        title: 'Complaint',
        description: 'A feature that handles submitting complaints, and managing them'
    },
    {
        icon: 'S',
        title: 'Support',
        description: 'A feature that handles submitting support tickets, managing them and the documentation'
    },
    {
        icon: 'D',
        title: 'Documents',
        description: 'A feature that handles processing documents and attaching them to incidents, complaints and more'
    },
]