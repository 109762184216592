import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import HomePage from './pages/home';
import AuthenticationPage from './pages/qualityControl/authentication';
import NotFound from './pages/errors/notFound';

import './assets/css/main/main.css';
import AboutPage from './pages/introduction/AboutPage';
import Requirements from './pages/introduction/requirements';
import GettingStarted from './pages/introduction/GettingStarted';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* introduction */}
        <Route path='/intro/about/' element={<AboutPage />} />
        <Route path='/intro/requirements/' element={<Requirements />} />
        <Route path='/intro/getting-started/' element={<GettingStarted />} />
        <Route path="/qc/authentication/" element={<AuthenticationPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
