import React from 'react'
import PageContainer from '../componets/pageContainer'

function HomePageContent() {
    return (
        <div>
            <h1>Home page</h1>
        </div>
    )
}

function HomePage() {
    return (
        <PageContainer content={<HomePageContent />} />
    )
}

export default HomePage
